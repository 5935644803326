import HttpService from "@/modules/core/network/http-service";

class UserSindaService extends HttpService {
  constructor() {
    super("usuarios-sinda");
  }

  getUserByFilter(filter) {
    return this.get({
      buscar: filter
    });
  }
}

export default new UserSindaService();
