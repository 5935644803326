export default {
  choice(default_value, choice, condicional) {
    if (condicional) {
      return choice;
    }
    return default_value;
  },
  pluralize(length, plural) {
    plural = plural ? plural : "s";
    if (length > 1) {
      return plural;
    }
    return "";
  },

  // Geral
  informativo_safra:
    "A partir de 2021, todos os produtores que aderirem ao ABR, ABR/BCI, automaticamente, aderem ao SBRHVI por prazo indeterminado.",
  titulo_botoes_selecionar_sistemas:
    "Selecione os sistemas nos quais você deseja realizar o convite.",
  titulo_selecionar_produtor_grupo:
    "1- Selecione o Produtor ou Grupo de Produtores",
  confirmar_unidades_produtivas_pertencentes_produtor_grupo_para_convite:
    "2- Confirme as Unidades Produtivas pertencentes ao Produtor/Grupo que serão convidadas para o ABR/BCI",
  passos_sistemas: {
    1: "Definir Sistemas",
    2: "Definir Convites",
    3: "Confirmação"
  },
  nenhum_registro_selecionado: "Nenhum",
  nenhum_registro_selecionado_tabelas: "Nenhum registro selecionado",
  alterar_produtor_grupo: "Alterar Produtor/Grupo",
  adicionar_produtor_grupo: "Adicionar Produtor/Grupo",
  produtor_grupo_selecionado: "Produtor/Grupo selecionado",
  modal_selecionar_produtor_grupo:
    "Selecione o tipo de cadastro a ser convidado",
  selecionar_responsavel_up_programa:
    "3 - Selecione um responsável para responder pelas Unidades Produtivas no Programa ABR/BCI.",
  selecionar_responsavel_sbrhvi:
    "1- Selecione um Responsável Líder para responder pelo Produtor/Grupo no Programa SBRHVI.",
  informativo_apos_definir_up_produtor_grupo_para_convite:
    "Após selecionar, você poderá definir as Unidades Produtivas do Produtor ou Grupo de Produtores desejado.",
  adicionar_responsavel: "Adicionar Responsável",
  busca_produtor_grupo: "Busque pelo CPF, nome ou e-mail do usuário",
  informativo_usuario_nao_encontrado_busca:
    "Não encontrou o usuário desejado? Adicione um novo no botão Novo usuário",
  novo_convidado: "Novo convidado",
  titulo_novo_convidado: "Selecionar/Cadastrar Convidado",

  // ABR
  responsavel_abr: "Responsável selecionado para o ABR/BCI",
  responsaveis_abr: "Responsáveis selecionado(s) para o ABR/BCI",

  // SBRHVI
  responsavel_sbrhvi: "Responsáveis selecionado(s) para o SBRHVI"
};
